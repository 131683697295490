<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-divider class="m-0"></v-divider>
              <v-col cols="12">
                <v-expansion-panels accordion v-model="panel">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
                      <div class="text-center font-weight-bold">
                        Search/Filters
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form v-on:submit.prevent="hitAdvanceSearch(false)">
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.vendorSearch"
                                :items="vendorsFilter"
                                item-text="name"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                chips
                                small-chips
                                hide-details
                                deletable-chips
                                @change="vendorChange($event)"
                                label="Select Vendor*"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content>
                                  <v-list-item-title
                                      v-html="data.item.name">
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="4" v-if="searchFormData.vendorSearch">
                            <v-autocomplete
                                v-model="searchFormData.userSearch"
                                :items="usersFilter"
                                item-text="name"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                chips
                                hide-details
                                small-chips
                                deletable-chips
                                @change="userChange($event)"
                                label="Select User*"
                            >
<!--                              <template v-slot:item="data">-->
<!--                                <v-list-item-content>-->
<!--                                  <v-list-item-title-->
<!--                                      v-html="data.item.name + ' (' + data.item.email + ')'">-->
<!--                                  </v-list-item-title>-->
<!--                                </v-list-item-content>-->
<!--                              </template>-->
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="4" v-if="searchFormData.vendorSearch && searchFormData.userSearch">
                            <v-select
                                dense outlined
                                hide-details
                                clearable
                                @change="drChange"
                                :items="dateRangeFilter"
                                v-model="searchFormData.drSearch"
                                item-text="title"
                                item-value="value"
                                label="Date Range"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="4" v-if="searchFormData.vendorSearch && searchFormData.userSearch">
                            <v-text-field type="date"
                                          dense outlined
                                          hide-details v-model="searchFormData.fromDateSearch"
                                          label="From Date"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" v-if="searchFormData.vendorSearch && searchFormData.userSearch">
                            <v-text-field type="date"
                                          dense outlined
                                          hide-details v-model="searchFormData.toDateSearch"
                                          label="To Date"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-if="searchFormData.vendorSearch && searchFormData.userSearch">
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" type="submit" style="width: 100%"
                                    class="btn btn-success theme-btn ml-auto mr-2">
                              Generate Logs
                              <v-icon class="ml-2" small>fas fa-tasks</v-icon>
                            </button>
                          </v-col>
                          <!--                          <v-col cols="12" sm="6">-->
                          <!--                            <button :disabled="loading" style="width: 100%" @click="hitAdvanceSearch(true)"-->
                          <!--                                    class="btn btn-primary ml-auto">-->
                          <!--                              Reset-->
                          <!--                              <v-icon class="ml-2" small>fas fa-times</v-icon>-->
                          <!--                            </button>-->
                          <!--                          </v-col>-->
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-divider class="m-0"></v-divider>
              <div v-show="dataLoaded">
                <v-card-title>
                  User Logs ({{ addTSBVZ(totalRecords) }})
                </v-card-title>
                <v-row>
                  <v-col cols="12">
                    <v-pagination
                        color="#00004d"
                        :total-visible="15"
                        @input="refreshData(false)"
                        :disabled="loading"
                        v-model="pagination.page"
                        :length="pageCount">
                    </v-pagination>
                  </v-col>
                </v-row>
                <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"></v-skeleton-loader>
                <v-card-text v-if="!isLoaded">
                  <v-row v-if="items.length > 0">
                    <v-col cols="12" sm="4" v-for="(item, index) in items" :key="index">
                      <v-card
                          class="mx-auto"
                          outlined
                          raised
                      >
                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              <div v-if="item.model === 'sms' || item.model === 'email'">
                                <p class="font-size-lg font-weight-bold m-0"
                                   v-html="setHtml(item.action)"
                                ></p><br>
                                <p class="m-0">{{ formatDateAndTime(item.created_at) }}</p>
                              </div>
                              <div v-else>
                                <p class="font-size-lg font-weight-bold m-0"
                                   v-html="setHtml(replaceCommaLine(item.action))"
                                ></p><br>
                                <p class="m-0">{{ formatDateAndTime(item.created_at) }}</p>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <p class="text-center"><i>No Record Found.</i></p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :options.sync="pagination"
                    :server-items-length="totalRecords"
                    @update:items-per-page="refreshData(false)"
                    :footer-props="{
                  itemsPerPageOptions: rowsPerPageItems,
                  showFirstLastPage: true
                }"
                    :loading="loading"
                    v-show="!isLoaded"
                    mobile-breakpoint="100"
                    class="elevation-0 custom-datatable"
                >
<!--                  <template v-slot:top="{ pagination, options, updateOptions }">-->
<!--                    <v-data-footer-->
<!--                        class="custom-datatable-footer3"-->
<!--                        :pagination="pagination"-->
<!--                        :options="options"-->
<!--                        @update:options="updateOptions"-->
<!--                        :items-per-page-options="rowsPerPageItems"-->
<!--                        :show-first-last-page="true"-->
<!--                        items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>-->
<!--                  </template>-->
                </v-data-table>
                <v-row>
                  <v-col cols="12">
                    <v-pagination
                        color="#00004d"
                        :total-visible="15"
                        @input="refreshData(false)"
                        :disabled="loading"
                        v-model="pagination.page"
                        :length="pageCount">
                    </v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  GET_USER_LOGS
} from "@/core/services/store/users.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {GET_VENDORS_DETAILS, VENDORS_SIMPLE_FULL_LIST} from "@/core/services/store/vendors.module";

export default {
  name: 'user-logs',
  data() {
    return {
      panel: 0,
      draw: 1,
      searchQuery: "",
      loading: false,
      dataLoaded: false,
      isLoaded: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      headers: [
        {
          text: 'User',
          value: 'user_id',
          name: 'user_id',
          filterable: false,
          sortable: false
        },
        {
          text: "Created",
          value: "created_at",
          name: "created_at",
          filterable: false,
          sortable: true
        },
        {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
        {text: 'Action', value: 'actions', data: null, name: null, filterable: false, sortable: false},
      ],
      items: [],
      vendorsFilter: [],
      usersFilter: [],
      searchFormData: {
        drSearch: '1',
        vendorSearch: '',
        userSearch: '',
        fromDateSearch: '',
        toDateSearch: '',
        logType: 'Logged In/Logged Out'
      },
      logTypesFilter: [],
    }
  },
  watch: {},
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].data +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.getVendors();
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 1 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(GET_USER_LOGS, {
          userId: this.searchFormData.userSearch,
          q: query,
          columns: this.columns
        }).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete '" + item.name + "'?", clr: 'red', callback: function () {
          this.$store.dispatch(DELETE_USER, {
            'user_id': item.id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    hitAdvanceSearch(reset = false) {
      this.pagination.page = 1
      if (reset) {
        this.searchFormData = {
          drSearch: '1',
          vendorSearch: '',
          userSearch: '',
          fromDateSearch: '',
          toDateSearch: '',
          logType: 'Logged In/Logged Out'
        };
      }
      if (this.searchFormData.vendorSearch && this.searchFormData.userSearch && this.searchFormData.fromDateSearch && this.searchFormData.toDateSearch && this.searchFormData.logType) {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
          this.dataLoaded = true
        });
      }
    },
    getVendors() {
      this.$store.dispatch(VENDORS_SIMPLE_FULL_LIST).then((data) => {
        this.vendorsFilter = data.records.vendorsList;
      });
    },
    getVendorUsers(id) {
      this.$store.dispatch(GET_VENDORS_DETAILS, id).then((data) => {
        this.usersFilter = data.records.all_users
        // this.logTypesFilter = data.records.logTypes
      })
    },
    vendorChange(evt) {
      this.searchFormData.userSearch = ''
      if (evt) {
        this.getVendorUsers(evt)
      }
    },
    userChange(evt) {
      if (evt) {
        this.searchFormData.drSearch = '1'
        this.drChange("1")
        this.hitAdvanceSearch(false)
      }
    },
    drChange(v) {
      if (v) {
        if (v === "1") {
          let date = new Date();
          let cdate = new Date();
          let lastmonths = new Date(cdate.setMonth(cdate.getMonth() - 1));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = lastmonths.getFullYear() + '-' + ('0' + (lastmonths.getMonth() + 1)).slice(-2) + '-' + ('0' + lastmonths.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "2") {
          let date = new Date();
          let cdate = new Date();
          let last3months = new Date(cdate.setMonth(cdate.getMonth() - 2));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last3months.getFullYear() + '-' + ('0' + (last3months.getMonth())).slice(-2) + '-' + ('0' + last3months.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "3") {
          let date = new Date();
          let cdate = new Date();
          let last6months = new Date(cdate.setMonth(cdate.getMonth() - 5));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last6months.getFullYear() + '-' + ('0' + (last6months.getMonth())).slice(-2) + '-' + ('0' + last6months.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "4") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = (date.getFullYear() - 1) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        } else if (v === "5") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = date.getFullYear() + '-01-01';
          this.searchFormData.toDateSearch = toDate
          this.searchFormData.fromDateSearch = fromDate
        }
      } else {
        this.searchFormData.toDateSearch = this.$store.state.auth.c_d
        this.searchFormData.fromDateSearch = this.$store.state.auth.c_d
      }
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.users.errors
    }),
    pageCount() {
      // return this.totalRecords / this.pagination.rowsPerPage
      return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
    }
  }
};
</script>